export const doSendRequestAccess = async (payload: any) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_API}/api/email-marketing/request-access`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  return await response.json();
};
