import { FC, PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import { Header } from "../Header";
import { Footer } from "../Footer";
import "react-toastify/dist/ReactToastify.css";

type LandingLayoutProps = PropsWithChildren<{}>;
export const LandingLayout: FC<LandingLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />

      <ToastContainer />
    </>
  );
};
