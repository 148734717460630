import { ReactComponent as BannerSvg } from "@/assets/illustrations/home-banner.svg";
import { ReactComponent as MobileBannerSvg } from "@/assets/illustrations/home-banner-mobile.svg";
import { Button, Text } from "@/components";
import { doSendRequestAccess } from "@/apis";
import { validateEmail } from "@/utils";
import clsx from "clsx";
import { useState } from "react";
import { toast } from "react-toastify";

export const Banner = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const sendRequestAccess = async () => {
    if (!email) {
      toast.error("Please fill out your email");
      return;
    };

    if (!validateEmail(email)) {
      toast.error("Email is invalid");
      return;
    }

    try {
      setLoading(true);

      await doSendRequestAccess({ email });
      
      toast.success("Your request is accepted!");
      setEmail("");
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="relative h-[calc(100vh-220px)] container mx-auto overflow-hidden">
      <BannerSvg className="hidden sm:block md:w-full 2xl:min-w-[1384px] h-auto" />
      <div className="relative visible sm:hidden mt-10">
        <MobileBannerSvg className="w-full visible sm:hidden h-auto" />
      </div>

      <div className="absolute left-4 right-4 md:left-0 md:right-0 top-5 md:top-20 flex flex-col items-center py-10 gap-2.5 overflow-hidden">
        <div className="flex text-center gap-x-2.5 text-white font-medium mb-0 md:mb-3">
          <span className="text-[28px] md:text-[44px]">Private launch initiated</span>
        </div>

        <Text.Heading className="hidden sm:block max-w-[1200px] text-center text-secondary text-[34px] md:text-[54px] 2xl:max-w-[100%]">
          Swift Rejection & Smart Selection
        </Text.Heading>

        <Text.Heading className="visible sm:hidden text-center text-secondary text-[34px]">
          Swift Rejection &<br />Smart Selection
        </Text.Heading>

        <div className="text-center text-[16px] md:text-[24px] w-full max-w-[300px] md:max-w-[100%] my-0 md:my-2.5">
          AI-Powered Deal Screening, Competitive Analysis, & Due Diligence for Investors
        </div>

        <div className="w-full max-w-[900px] mt-7">
          <div className="flex flex-col md:flex-row items-center md:justify-center mb-2">
            <div className="w-full md:w-auto flex-1 md:flex-auto mr-0 md:mr-4 mb-3 md:mb-0">
              <input
                className="w-full text-sm md:text-md input-email max-h-[58px] shadow-[0_6px_#000]"
                placeholder="Enter email to request access"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <Button
              className={clsx(
                "w-full md:w-[212px] h-[54px] md:w-auto py-4 text-sm md:text-md flex justify-center",
                {
                  "bg-[#E8E8E8]": !email || loading
                }
              )}
              onClick={sendRequestAccess}
              loading={loading}
            >
              Request Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
