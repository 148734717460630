import clsx from "clsx";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from "react";
import { Link } from "react-router-dom";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    outlined?: boolean;
    loading?: boolean;
  };
export const Button: FC<ButtonProps> = ({
  className: cn,
  href,
  outlined = false,
  loading = false,
  children,
  ...props
}) => {
  const className = clsx("default-btn px-10 py-2.5 rounded cursor-pointer", { outlined }, cn);

  const LoadingComponent = () => (
    <svg
      className="animate-spin"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C9.02528 2 10.0335 2.26273 10.9283 2.76312C11.8232 3.26351 12.5749 3.98487 13.1118 4.85837C13.6486 5.73187 13.9527 6.72836 13.9949 7.75276C14.0372 8.77717 13.8162 9.79532 13.3531 10.7101C12.89 11.6248 12.2002 12.4056 11.3496 12.978C10.499 13.5503 9.51585 13.8952 8.49405 13.9796C7.47226 14.064 6.44587 13.8852 5.51283 13.4602C4.57979 13.0352 3.77122 12.3782 3.16428 11.5519L4.42157 10.6284C4.87071 11.2399 5.46905 11.7261 6.15949 12.0406C6.84994 12.3551 7.60947 12.4874 8.3656 12.4249C9.12173 12.3624 9.84923 12.1073 10.4787 11.6837C11.1082 11.2601 11.6186 10.6823 11.9613 10.0054C12.304 9.32853 12.4675 8.57511 12.4362 7.81705C12.405 7.05898 12.18 6.32158 11.7827 5.67519C11.3854 5.02881 10.8292 4.495 10.167 4.12471C9.50475 3.75442 8.75871 3.56 8 3.56V2Z"
        fill="#2EC6CC"
      />
      <path
        d="M3.1685 11.5576C2.51044 10.6639 2.11315 9.60521 2.0208 8.49921C1.92846 7.39322 2.14469 6.28328 2.64545 5.29283C3.1462 4.30238 3.91186 3.47022 4.85728 2.8889C5.8027 2.30758 6.89083 1.99988 8.00068 2L8.0005 3.56C7.17922 3.55991 6.374 3.78761 5.67439 4.21779C4.97478 4.64797 4.40819 5.26376 4.03763 5.99669C3.66707 6.72963 3.50706 7.55098 3.57539 8.36942C3.64373 9.18785 3.93772 9.9713 4.42469 10.6326L3.1685 11.5576Z"
        fill="#121212"
      />
    </svg>
  )

  if (!!href)
    return (
      <Link to={href} className={className} {...props}>
        {loading ? <LoadingComponent /> : children}
      </Link>
    );

  return (
    <button className={className} {...props}>
      {loading ? <LoadingComponent /> : children}
    </button>
  );
};
