import clsx from "clsx";
import { FC, PropsWithChildren } from "react";
import { Link as NavLink, LinkProps as NavLinkProps } from "react-router-dom";

type LinkProps = NavLinkProps &
  PropsWithChildren<{ active?: boolean; disableDefaultConfig?: boolean }>;
export const Link: FC<LinkProps> = ({
  className,
  children,
  active = false,
  disableDefaultConfig = false,
  ...props
}) => {
  const defaultClassName = disableDefaultConfig
    ? ""
    : clsx("px-10 py-1", { "border-b-2 border-b-secondary": active });
  return (
    <NavLink className={clsx(defaultClassName, className)} {...props}>
      {children}
    </NavLink>
  );
};
