import {
  Banner,
} from "./sections";

export const HomePage = () => {
  return (
    <div className="flex flex-col gap-y-12 md:gap-y-16">
      <Banner />
    </div>
  );
};
