import clsx from "clsx";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type HeadingProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    size?: "md" | "sm";
  }
>;
export const Heading: FC<HeadingProps> = ({
  className,
  children,
  size = "md",
  ...props
}) => {
  return (
    <div
      className={clsx(
        "leading-tight font-bold",
        {
          "text-2xl md:text-4.5xl xl:text-5.5xl": size === "sm",
          "text-2xl md:text-5xl xl:text-6.5xl": size === "md",
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
