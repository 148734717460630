import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

enum Screen {
  base = "",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}
type Context = {
  screen: "" | "sm" | "md" | "lg" | "xl" | "2xl";
};

const AppContext = createContext<Context>({
  screen: "",
});

export const AppProvider = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const [screen, setScreen] = useState<Screen>(Screen.base);

  const handleScreenWidth = () => {
    setScreen(
      window.innerWidth >= 1536
        ? Screen["2xl"]
        : window.innerWidth >= 1280
        ? Screen.xl
        : window.innerWidth >= 1024
        ? Screen.lg
        : window.innerWidth >= 768
        ? Screen.md
        : window.innerWidth >= 640
        ? Screen.sm
        : Screen.base
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidth);

    handleScreenWidth();

    return () => {
      window.removeEventListener("resize", handleScreenWidth);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AppContext.Provider value={{ screen }}>{children}</AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
