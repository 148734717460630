import { Link as NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="absolute left-0 right-0 bottom-0 footer pt-16">
      <div className="border-t border-[#fff3]">
        <div className="container mx-auto py-5">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div className="text-[#ffffff80] font-clashGrotesk">
              © {new Date().getFullYear()} T12 Inc. All Rights Reserved.
            </div>

            <div className="flex items-center gap-4 justify-between">
              <NavLink
                className="text-[#ffffff80] font-clashGrotesk text-lg"
                to="legal/terms"
              >
                Terms of Use
              </NavLink>

              <NavLink
                className="text-[#ffffff80] font-clashGrotesk text-lg"
                to="/legal/privacy-policy"
              >
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
